import Vue from 'vue'
import SvgIcon from '@/components/icon-svg'

// 全局注册组件
Vue.component('svg-icon', SvgIcon)

const requireAll = context => context.keys().map(context)
const req = require.context('./svg', true, /\.svg$/)

requireAll(req)
