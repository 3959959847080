/*
 * @Description: 路由配置
 * @Author: hai-27
 * @Date: 2020-02-07 16:23:00
 * @LastEditors: hai-27
 * @LastEditTime: 2020-02-27 13:58:48
 */
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const routes = [

    {
        path: '/',
        name: 'Home',
        component: () => import('../views/Home2.vue')
    },

    {
        path: '/ProductXQ',
        name: 'ProductXQ',
        component: () => import('../views/ProductXQ.vue')
    },
    {
        path: '/error',
        name: 'Error',
        component: () => import('../components/Error.vue')
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login.vue')
    },
    {
        path: '/service',
        name: 'Service',
        component: () => import('../views/Service.vue')
    },
    {
        path: '/management',
        name: 'Management',
        component: () => import('../views/Management.vue')
    },
    {
        path: '/wenjuan',
        name: 'wenjuan',
        component: () => import('../views/WenJuan.vue')
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('../views/Register.vue')
    },
    {
        path: '/ForgotPassword',
        name: 'ForgotPassword',
        component: () => import('../views/ForgotPassword.vue')
    },
    {
        path: '/procurement',
        name: 'Procurement',
        component: () => import('../views/Procurement.vue')
    },
    {
        path: '/product',
        name: 'Product',
        component: () => import('../views/Product.vue')
    },
    {
        path: '/newbidding',
        name: 'newBidding',
        component: () => import('../views/Bidding/Bidding.vue')
    },
    {
        path: '/goodsnew',
        name: 'Goods',
        component: () => import('../views/Bidding/goods_one.vue')
    },
    {
        path: '/auction',
        name: 'Auction',
        component: () => import('../views/Auction.vue')
    },
    {
        path: '/auction_split',
        name: 'Auction_split',
        component: () => import('../views/Auction_split.vue')
    },
    {
        path: '/about',
        name: 'About',
        component: () => import('../views/About.vue')
    },
    {
        path: '/collect',
        name: 'Collect',
        component: () => import('../views/Collect.vue'),
        meta: {
            requireAuth: true // 需要验证登录状态
        }
    },
    {//实时资讯
        path: "/zixun",
        name: 'zixun',
        component: () => import('../views/zixun.vue'),
        // meta: {
        //   requireAuth: true // 需要验证登录状态
        // }
    },
    {//个人中心
        path: "/personalCenter",
        name: 'personalCenter',
        component: () => import('../views/personalCenter.vue'),
        // meta: {
        //   requireAuth: true // 需要验证登录状态
        // }
    },
    {//个人中心
        path: "/personalCenterSecond",
        name: 'personalCenterSecond',
        component: () => import('../views/personalCenterSecond.vue'),
        // meta: {
        //   requireAuth: true // 需要验证登录状态
        // }
    },
    {//招商公告-详情页面
        path: "/zhaoshangone",
        name: 'zhaoshangone',
        component: () => import('../views/ZhaoShangOne.vue'),
        // meta: {
        //   requireAuth: true // 需要验证登录状态
        // }
    },
    {//闲置调剂-详情页面
        path: "/xianzhitiaojione",
        name: 'xianzhitiaojione',
        component: () => import('../views/XianZhiTiaoJiOne.vue'),
        // meta: {
        //   requireAuth: true // 需要验证登录状态
        // }
    },
       {// 进场交易-详情页面
        path: "/JinChangJiaoYiOne",
        name: 'JinChangJiaoYiOne',
        component: () => import('../views/JinChangJiaoYi/JinChangJiaoYiOne.vue'),
        // meta: {
        //   requireAuth: true // 需要验证登录状态
        // }
    },
    {//首页最新成交更多
        path: "/chakan",
        name: 'Chakan',
        component: () => import('../views/Chakan.vue'),
        // meta: {
        //   requireAuth: true // 需要验证登录状态
        // }
    },
    //Zheline
    {//首页最新成交查看
        path: "/zheline",
        name: 'Zheline',
        component: () => import('../views/Zheline.vue'),
        // meta: {
        //   requireAuth: true // 需要验证登录状态
        // }
    },
    {  //资讯详情页面
        path: "/zxXiangQing",
        name: 'zxXiangQing',
        component: () => import('../views/zxXiangQing.vue'),
        // meta: {
        //   requireAuth: true // 需要验证登录状态
        // }
    },

    {  //资讯详情页列表页面
        path: "/XiangQingList",
        name: 'XiangQingList',
        component: () => import('../views/XiangQingList.vue'),
        // meta: {
        //         //   requireAuth: true // 需要验证登录状态
        //         // }
    },
    {  //公告详情页列表页面
        path: "/NoticeList",
        name: 'NoticeList',
        component: () => import('../views/NoticeList.vue'),
        // meta: {
        //         //   requireAuth: true // 需要验证登录状态
        //         // }
    },
    {  //招商公告页列表页面
        path: "/ZhaoshangList",
        name: 'zhaoshangList',
        component: () => import('../views/ZhaoshangList.vue'),
        // meta: {
        //         //   requireAuth: true // 需要验证登录状态
        //         // }
    },
    {  //闲置调剂页列表页面
        path: "/xianZhiTiaoJiList",
        name: 'xianZhiTiaoJiList',
        component: () => import('../views/XianZhiTiaoJiList.vue'),
    },
    {  // 进场交易-列表页面
        path: "/JinChangJiaoYiList",
        name: 'JinChangJiaoYiList',
        component: () => import('../views/JinChangJiaoYi/JinChangJiaoYiList.vue'),
    },
    {  //合同详情页面
        path: "/contracDetail",
        name: 'contracDetail',
        component: () => import('../views/contracDetail.vue'),
        // meta: {
        //         //   requireAuth: true // 需要验证登录状态
        //         // }
    },
    {  //首页模糊查询 列表页
        path: "/HomeSearch",
        name: 'HomeSearch',
        component: () => import('../views/HomeSearch.vue'),
    },
]

const router = new Router({
    // base: '/dist',
    // mode: 'history',
    routes
})

/* 由于Vue-router在3.1之后把$router.push()方法改为了Promise。所以假如没有回调函数，错误信息就会交给全局的路由错误处理。
vue-router先报了一个Uncaught(in promise)的错误(因为push没加回调) ，然后再点击路由的时候才会触发NavigationDuplicated的错误(路由出现的错误，全局错误处理打印了出来)。*/
// 禁止全局路由错误处理打印
// const originalPush = Router.prototype.push;
// Router.prototype.push = function push(location, onResolve, onReject) {
//     if (onResolve || onReject)
//         return originalPush.call(this, location, onResolve, onReject)
//     return originalPush.call(this, location).catch(err => err)
// }

export default router
