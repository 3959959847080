/*
 * 入口文件
 * @Author: hai-27
 * @Date: 2020-02-07 16:23:00
 * @LastEditors: hai-27
 * @LastEditTime: 2020-03-04 23:38:41
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//vue行政区划组件
import vRegion from 'v-region'
Vue.use(vRegion)
import 'swiper/css/swiper.css'
import dataV from '@jiaminghi/data-view'

Vue.use(dataV)

import {post,fetch,allRequest} from '@/utils/httpRequest'
Vue.prototype.$post=post;
Vue.prototype.$fetch=fetch;
Vue.prototype.$all=allRequest;
import ElementUI from 'element-ui';
// import '@/assets/css/theme/index.css'
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

//百度地图
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  ak: 'yE95zVB9YzY6uSSUp5869GDCZQGNaGey'
});

import '@/assets/css/bootstrap-grid.scss'
import '@/assets/css/global.scss'
// 全局函数及变量
import Global from './Global';
Vue.use(Global);
//引入 svg
import '@/icons'
//引入v-charts 图表
import VCharts from 'v-charts'
Vue.use(VCharts)

// 引入echarts
import echarts from 'echarts'
Vue.prototype.$echarts = echarts

import Axios from 'axios';
Vue.prototype.$axios = Axios;
// 全局请求拦截器
Axios.interceptors.request.use(
  config => {
    return config;
  },
  error => {
    // 跳转error页面
    router.push({ path: "/error" });
    return Promise.reject(error);
  }
);
// 全局响应拦截器

Axios.interceptors.response.use(
  // res => {
    // let item = localStorage.getItem("mytime");
    // if(item){
    //   let date = new Date().getTime();

    //   if (date - item > tempTime) {
    //     localStorage.clear()
    //     location.reload();
    // }
    // }

  //   if (res.data.code === "401") {
  //     // 401表示没有登录
  //     // 提示没有登录
  //     Vue.prototype.notifyError(res.data.msg);
  //     // 修改vuex的showLogin状态,显示登录组件
  //     store.dispatch("setShowLogin", true);
  //   }
  //   if (res.data.code === "500") {
  //     // 500表示服务器异常
  //     // 跳转error页面
  //     router.push({ path: "/error" });
  //   }
  //   return res;
  // },
  // error => {
  //   // 跳转error页面
  //   router.push({ path: "/error" });
  //   return Promise.reject(error);
  // }
);

// 全局拦截器,在进入需要用户权限的页面前校验是否已经登录
let tempTime = 36000000;
router.beforeResolve((to, from, next) => {
  // if (to.meta.login) {
    let item = localStorage.getItem("mytime");
  if (item) {
    let date = new Date().getTime();

    if (date - item > tempTime) {
      localStorage.clear()
      next({
        name: "login"
      });
      location.reload();
    }
  }
  // }
  const loginUser = store.state.user.user;
  // 判断路由是否设置相应校验用户权限
  if (to.meta.requireAuth) {
    let item = localStorage.getItem("mytime");
    let date = new Date().getTime();
    if (date - item > tempTime) {
      localStorage.clear()
      next({
        name: "login"
      });
  } else {
    if (!loginUser) {
      // 没有登录，显示登录组件
      store.dispatch("setShowLogin", true);
      if (from.name == null) {
        //此时，是在页面没有加载，直接在地址栏输入链接，进入需要登录验证的页面
        next("/");
        return;
      }
      // 终止导航
      next(false);
      return;
    }
  }
  }
  next();
});

// 相对时间过滤器,把时间戳转换成时间
// 格式: 2020-02-25 21:43:23
Vue.filter('dateFormat', (dataStr) => {
  var time = new Date(dataStr);
  function timeAdd0 (str) {
    if (str < 10) {
      str = '0' + str;
    }
    return str;
  }
  var y = time.getFullYear();
  var m = time.getMonth() + 1;
  var d = time.getDate();
  var h = time.getHours();
  var mm = time.getMinutes();
  var s = time.getSeconds();
  return y + '-' + timeAdd0(m) + '-' + timeAdd0(d) + ' ' + timeAdd0(h) + ':' + timeAdd0(mm) + ':' + timeAdd0(s);
});

//全局组件
import MyMenu from './components/MyMenu';
Vue.component(MyMenu.name, MyMenu);
import MyList from './components/MyList';
Vue.component(MyList.name, MyList);
import MyLogin from './components/MyLogin';
Vue.component(MyLogin.name, MyLogin);
import MyRegister from './components/MyRegister';
Vue.component(MyRegister.name, MyRegister);
import Pagination from './components/Pagination';
Vue.component(Pagination.name, Pagination);
import Carousel from './components/Carousel';
Vue.component(Carousel.name, Carousel);
Vue.config.productionTip = false;


//挂载vue原型链
//Vue.prototype.$setWs = wsConnection;

new Vue({
  router,
  data: function(){
      return {
        websock: null,
        lockReconnect: false, //是否真正建立连接
        timeout: 15*1000, //30秒一次心跳
        timeoutObj: null, //心跳心跳倒计时
        serverTimeoutObj: null, //心跳倒计时
        timeoutnum: null, //断开 重连倒计时
      }
  },
  store,
  render: h => h(App)
}).$mount('#app')
