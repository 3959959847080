<template>
  <div>
    <div class="pic-box">
      <pic-zoom :url="pImgSrc" :scale="3"></pic-zoom>
    </div>
    <el-row  class="box">
    <div class="urlimg" v-for="(item,i) in imagesList" :key="i">
       <el-image
      :src="item"
      :lazy='true'
      style="width:100%; height:100%;"
      :preview-src-list="imagesList"
      @click="getIndex(item)"
       ></el-image>
    </div>
    </el-row>
    <!-- <div class="box" v-for="(item,i) in imagesList" :key="i">
        <div
            v-for="(img,index) in item.imgUrl"
            :key="index"
            @click="getIndex(img.url)"
            class="img-url"
          >
            <img :src="img.url" />
          </div>
    </div> -->
  </div>
</template>
<script>
import PicZoom from "./PicZoom";
export default {
  name: "Carousel",
  components: {
    PicZoom
  },
  data() {
    return {
      pImgSrc: "",
    };
  },
  props: {
    imgSrc: {
      type: String,
      default: ""
    },
    imagesList: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  mounted(){

  },
  methods: {
    getIndex(imgSrc) {
      this.pImgSrc = imgSrc;
    }
  },
  watch: {
    imgSrc: {
      handler(aImgSrc, pImgSrc) {
        this.pImgSrc = aImgSrc;
      },
      immediate: true
    }
  }
};
</script>
<style lang="scss" scoped>
.box {
  // text-align: center;
  width: 362px;
  height: 165px;
  overflow: hidden;
  margin-top: 10px;
  border: 1px solid #eee;
  border-radius: 2px;
  // border: 1px solid red;
}
.pic-box {
  position:relative;
  width: 362px;
  height: 362px;
  border: 1px solid #eee;
}

.urlimg{
  width: 85px;
  height: 80px;
  display: inline-block;
  padding: 5px;
}
/deep/ .el-image-viewer__close{
  top:140px;
}
</style>
