<!--
 * @Description: 项目根组件
 * @Author: hai-27
 * @Date: 2020-02-07 16:23:00
 * @LastEditors: hai-27
 * @LastEditTime: 2020-04-05 13:14:48
 -->
<template>
  <div id="app" name="app">
    <div class="float-bar" style="height: 100px;background-color: white;top: 0px;" :class="barFlag" v-if="tabsFlag">
      <div class="d-flex container">
        <div class="logo">
          <router-link to="/">
            <img src="./assets/imgs/logo.png" alt/>
          </router-link>
        </div>
        <div style="text-align:center;margin: 1.2rem">
          <el-menu
              :default-active="activeIndex"
              mode="horizontal"
              active-text-color="#e34545"
              router
          >
            <el-menu-item index="/" >首页</el-menu-item>
            <!-- <el-menu-item index="/zixun" style="font-size:16px">资讯中心</el-menu-item> -->
            <el-menu-item index="/newbidding" >竞价大厅</el-menu-item>
            <el-menu-item index="/JinChangJiaoYiList" >进场交易</el-menu-item>
            <el-menu-item index="/ZhaoshangList" >招商公告</el-menu-item>
            <el-menu-item index="/xianZhiTiaoJiList" >闲置调剂</el-menu-item>
            <el-menu-item index="/service" >交易规则</el-menu-item>
            <el-menu-item index="/about" >关于我们</el-menu-item>

<!--            <el-menu-item index="" >-->
<!--              <el-image-->
<!--                  :src="require('./assets/images/ZMappScans1.png')"-->
<!--                  :lazy='true'-->
<!--                  :preview-src-list="[require('./assets/images/ZMappScans1.png')]"-->
<!--                  style="width:100%; height:100%;"-->
<!--              ></el-image>-->
<!--            -->
<!--            </el-menu-item>-->
            <el-menu-item index="" style="font-weight:800;color:#DC2225"
                          class="el-icon-phone">400-061-7109
            </el-menu-item>
            <!-- <el-menu-item index="/about" style="font-size:16px">挂牌交易</el-menu-item> -->
            <!--          <el-menu-item index="/service" style="font-size:16px">客户服务</el-menu-item>-->
          </el-menu>
        </div>
        <!--        <div class="so">-->

        <!--          <el-input placeholder="请输入搜索内容" v-model="search" size="large">-->
        <!--            <el-button slot="append" icon="el-icon-search" ></el-button>-->
        <!--          </el-input>-->
        <!--        </div>-->
      </div>
    </div>
    <el-container>
      <!-- 顶部导航栏 -->
      <div class="topbar">
        <div class="nav container">
          <div class="font-size-14">您好，欢迎来到中国资产交易网</div>
          <ul>
            <li v-if="!this.$store.getters.getUser">
              <el-button type="text" @click="login">登录</el-button>
              <span class="sep" style="display: inline-block;color: white">|</span>
              <el-button type="text" @click="register()">注册</el-button>
            </li>
            <!--            <li>-->
            <!--              <el-button type="text" @click="indexPage()">嵌套页1</el-button>-->
            <!--            </li>-->
            <!--            <li>-->
            <!--              <el-button type="text" @click="twoPage()">嵌套页2</el-button>-->
            <!--            </li>-->
            <li v-if="this.$store.getters.getUser">
              <span class="ml-2">欢迎 {{this.$store.getters.getUser.membername}}</span>
            </li>
            <!--            <li v-if="this.$store.getters.getUser">-->
            <!--              <router-link to="/mine">会员中心</router-link>-->
            <!--            </li>-->
            <li v-if="this.$store.getters.getUser">
              <el-button type="text" slot="reference">
                <div @click="mine(1)" style="color: #FFC000">用户中心</div>
              </el-button>
            </li>
            <li v-if="this.$store.getters.getUser">
              <el-button @click="messagea(1)" type="text" style="color: #FFC000">站内信</el-button>
              <el-badge is-dot class="item" v-if="whitedian"></el-badge>
            </li>
            <li v-if="this.$store.getters.getUser">
              <el-popover placement="top" width="180" v-model="visible">
                <p>确定退出登录吗？</p>
                <div style="text-align: right; margin: 10px 0 0">
                  <el-button size="mini" type="text" @click="visible = false">取消</el-button>
                  <el-button type="primary" size="mini" @click="logout">确定</el-button>
                </div>
                <el-button type="text" slot="reference">
                  <div style="color: #FFC000">退出</div>
                </el-button>
              </el-popover>
            </li>
            <li>
              <span class="ml-2 el-icon-phone">400-061-7109</span>
            </li>
            <!-- <li :class="getNum > 0 ? 'shopCart-full' : 'shopCart'">
              <router-link to="/shoppingCart">
                <i class="el-icon-share"></i> 执行订单
                <span class="num">({{ getNum }})</span>
              </router-link>
            </li> -->
          </ul>
        </div>
      </div>
      <!-- 顶部导航栏END -->

      <!-- 顶栏容器 -->
      <el-header height="auto">
        <div class="d-flex align-items-center container my-4">
          <div class="logo">
            <router-link to="/">
              <img src="./assets/imgs/logo.png" alt/>
            </router-link>
          </div>
          <el-menu
              :default-active="activeIndex"
              mode="horizontal"
              active-text-color="#e34545"
              router
              v-if="tabsFlag"
              class="header-menu"
          >
            <el-menu-item index="/">首页</el-menu-item>
            <!-- <el-menu-item index="/zixun" style="font-size:18px">资讯中心</el-menu-item> -->
            <el-menu-item index="/newbidding">竞价大厅</el-menu-item>
            <el-menu-item index="/JinChangJiaoYiList" >进场交易</el-menu-item>
            <el-menu-item index="/ZhaoshangList" >招商公告</el-menu-item>
            <el-menu-item index="/xianZhiTiaoJiList" >闲置调剂</el-menu-item>
            <el-menu-item index="/service">交易规则</el-menu-item>
            <el-menu-item index="/about">关于我们</el-menu-item>
          </el-menu>
          <div class="header-search d-none d-xl-block" >
            <el-input size="mini" placeholder="标的名称、项目编号" v-model="search">
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
              <el-button slot="append" @click="searchClick"> 搜索</el-button>
            </el-input>
          </div>
        </div>
      </el-header>
      <!-- 顶栏容器END -->

      <!--      &lt;!&ndash; 登录模块 &ndash;&gt;-->
      <!--      <MyLogin></MyLogin>-->
      <!--      &lt;!&ndash; 注册模块 &ndash;&gt;-->
      <!--      <MyRegister :register="register" @fromChild="isRegister"></MyRegister>-->

      <!-- 主要区域容器 -->
      <el-main>
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </el-main>
      <!-- 主要区域容器END -->

      <!-- 底栏容器 -->
      <el-footer>
        <div class="footer foot">
          <div class="container d-flex justify-content-between">
            <div class="foot-left">
              <div class="mb-3"><span class="mr-2">中国资产交易网</span><span>专业的废旧物资网络竞价平台</span></div>
              <div class="mb-3 foot-nav">
                <span @click="$router.push('About')">关于我们</span>
                <span @click="$router.push('About')">联系我们</span>
                <span @click="$router.push('Service')">帮助中心</span>
                <span @click="$router.push('Service')">用户协议</span>
              </div>
              <div class="foot-subtext">Copyright &copy;中国地方煤矿有限公司 版权所有 | 京ICP备10020052号-2</div>
            </div>
            <div class="foot-right">
              <div class="foot-right-box">
                <div>
                  <div>客服专线</div>
                  <div class="foot-right-num">400-061-7109</div>
                  <el-divider></el-divider>
                  <span class="foot-right-question" @click="gotoWenJuan"><svg-icon name="questionnaire"></svg-icon>问卷调查</span>
                </div>
<!--                <el-image-->
<!--                    :src="require('./assets/images/ZMappScans1.png')"-->
<!--                    :lazy='true'-->
<!--                    :preview-src-list="[require('./assets/images/ZMappScans1.png')]"-->
<!--                    class="foot-right-img"-->
<!--                ></el-image>-->
              </div>
            </div>
          </div>
        </div>
      </el-footer>
      <!-- 底栏容器END -->
    </el-container>
    <!--  客服二维码  -->
    <div class="cService">
      <div class="cService-icon">
        <svg-icon name="customerService"></svg-icon>
      </div>
      <div class="cService-img">
        <img :src="require('@/assets/imgs/customer-service.png')" alt="客服二维码">
      </div>

    </div>
  </div>
</template>

<script>
  import Stomp from "stompjs";
  import {mapActions, mapGetters} from "vuex";

  export const MQTT_SERVICE = 'ws://123.56.105.33:15674/ws'; // mqtt服务地址
  export const MQTT_USERNAME = 'gyh' // 连接用户名
  export const MQTT_PASSWORD = 'gyh123' //  连接密码
  export const Virtual_host = '/' //  侦听器端口
  export default {
    beforeUpdate () {
      this.activeIndex = this.$route.path;
    },
    mounted () {
      window.addEventListener ("scroll", this.showBar);

    },
    beforeDestroy () {
      clearInterval (this.clearIntervalParm);
    },
    data () {
      return {
        clearIntervalParm: '',
        whitedian: false,
        activeIndex: "/", // 头部导航栏选中的标签
        search: "", // 搜索条件
        // register: false, // 是否显示注册组件
        visible: false, // 是否退出登录
        menuShow: false,
        barFlag: 'show-false',//置顶搜索栏
        light: 0,//置顶搜索栏透明度
        tabsFlag: true,//tabs显示开关
        client: null
      };
    },
    created () {
      // this.connect();
      // 获取浏览器localStorage，判断用户是否已经登录
      // if (localStorage.getItem("user")) {
      //   // 如果已经登录，设置vuex登录状态
      //   this.setUser(JSON.parse(localStorage.getItem("user")));
      // }
      this.clearIntervalParm = setInterval (() => {
        const req = {}
        this.$set (req, 'memberid', this.$store.getters.getUser.id)
        // console.log('this.$store.getters')
        const temp = this.$store.getters.getUser
        if (this.$store.getters.getUser != null && this.$store.getters.getUser != "" && this.$store.getters.getUser != "undefined") {
          this.$post ('/shoppingui/bidShopping/messageList', req).then ((data) => {
            if (data.code == 0) {
              if (data.data > 0) {
                this.whitedian = true;
              }
            } else {
              this.setUser ("");
              this.setToken ("");
              sessionStorage.clear ();
              localStorage.clear ()
              this.$router.push ({path: '/login'});
            }

          });
        }

      }, 300000)
    },
    computed: {
      // ...mapGetters(["getUser", "getNum"])
    },
    watch: {
      //监听路由变化  关闭页面菜单栏
      "$route.path": function (newVal, oldVal) {
        if (newVal == '/login' || newVal == '/register' || newVal == '/personalCenter' || newVal == '/register' || newVal == '/personalCenterSecond') {
          this.tabsFlag = false;
        } else {
          this.tabsFlag = true;
        }
      }

    },
    methods: {
      messagea () {
        this.$router.push ({
          path: '/personalCenterSecond', query: {
            values: this.$messageGlobal
          }
        });
      },
      getNowYear () {
        const timeOne = new Date ()
        const year = timeOne.getFullYear ()
        const NOW_MONTHS_AGO = `${year}`
        return NOW_MONTHS_AGO
      },
      //嵌套页面
      indexPage () {
        this.$router.push ({path: '/IndexPage'})
      },
      twoPage () {
        this.$router.push ({path: '/TwoPage'})
      },
      ...mapActions (["setUser", "setToken", "setShowLogin", "setShoppingCart"]),
      ...mapGetters (["getToken", "getUser"]),
      // login() {
      //   // 点击登录按钮, 通过更改vuex的showLogin值显示登录组件
      //   this.setShowLogin(true);
      // },
      // 用户中心
      mine (value) {
        this.$router.push ({
          path: '/personalCenter', query: {
            values: value
          }
        });
      },
      // 退出登录
      logout () {
        this.setUser ("");
        this.setToken ("");
        sessionStorage.clear ();
        localStorage.clear ()
        this.visible = false;
        this.$message ({
          duration: 500,
          message: '退出成功',
          type: 'success',
          onClose: () => {
            this.$router.push ({path: '/login'});
          }
        })
        // 清空vuex登录信息
        // const tel = this.getUser().tel
        // if(tel == null){
        // this.$message({
        //             duration:500,
        //             message: '未登录',
        //             type: 'error',
        //             onClose:()=>{
        //             this.$router.push({path: '/login'});
        //             }
        //           })
        // }else{
        //   this.$post('/shoppingui/alogin/loginout', {tel: tel}).then((data) => {
        //         if (data.code == 0) {
        //           this.$message({
        //             duration: 1000,
        //             message: data.message,
        //             type: 'success',
        //             showClose: 'false'
        //           })
        //         } else {
        //           this.$message({
        //             message: data.message,
        //             type: 'error',
        //             showClose: 'true'
        //           })
        //         }
        //          this.$router.push({path: '/login'});
        //       })
        //   this.setUser("");
        //   this.setToken("");
        //   sessionStorage.clear();
        //   this.$router.push({path: '/login'});
        // }

      },
      //底部跳转竞价销售
      goBidding () {
        this.$router.push ({
          path: "/bidding"
        })
      },
      //挂牌交易
      goAbout () {
        this.$router.push ({
          path: '/about'
        })
      },
      //阳光采购
      goProcurement () {
        this.$router.push ({
          path: '/procurement'
        })
      },
      // // 接收注册子组件传过来的数据
      // isRegister(val) {
      //   this.register = val;
      // },
      // // 点击搜索按钮
      searchClick () {
        if (this.search != "") {
          let queryData = {};
          this.$set (queryData, 'targetname', this.search)
          let routeData = this.$router.resolve ({path: "/HomeSearch", query: queryData});
          window.open (routeData.href, '_blank');
          // // 跳转到全部商品页面,并传递搜索条件
          // this.$router.push({path: "/goods", query: {search: this.search}});
          this.search = "";
        }
      },
      showBar () {
        if (!!document.documentElement.scrollTop && document.documentElement.scrollTop > 0) {
          // 页面高度大于0执行操作
          this.barFlag = 'show-true'
        } else {
          // 页面高度小于0执行操作
          this.barFlag = 'show-false'
        }
      },
      login () {
        this.$router.push ({path: "/login"});
      },
      register () {
        this.$router.push ({path: "/register"});
      },
      //webSocket连接成功后回调函数
      onConnected (frame) {
        //绑定交换机exchange_pushmsg是交换机的名字rk_pushmsg是绑定的路由key
        var exchange = "/exchange/aabbcc/aaa";
        //创建随机队列用上面的路由key绑定交换机,放入收到消息后的回调函数和失败的回调函数
        this.client.subscribe (exchange, this.responseCallback, this.onFailed);
      },
      onFailed (frame) {
      },
      responseCallback (frame) {
        //接收到服务器推送消息，向服务器定义的接收消息routekey路由rk_recivemsg发送确认消息
        this.client.send ("/exchange/aabbcc/aaa", {"content-type": "text/plain"}, frame.body);
      },
      connect () {
        //这里填你rabbitMQ的连接ip地址直接替换localhost:15674就好其它的不用改
        this.client = Stomp.client ("ws://123.56.105.33:15674/ws")
        //填写你rabbitMQ登录的用户名和密码
        var headers = {
          "login": "gyh",
          "passcode": "gyh123",
          //虚拟主机，默认“/”
          "host": "/"
        };
        //创建连接,放入连接成功和失败回调函数
        this.client.connect (headers, this.onConnected, this.onFailed);
      },
      gotoWenJuan () {
        let routeData = this.$router.resolve ({path: "/wenjuan"});
        window.open (routeData.href, '_blank');
      },
    }
  };
</script>
<style scoped lang="scss">
  // .item {
  //   margin-top: -10px;
  //   margin-right: -10px;
  // }
  .el-header {
    background-color: #fff;
  }

  .el-menu.el-menu--horizontal {
    border: none;
  }

  .menu-list {
    margin-left: 20px;
  }

  .el-menu-demo {
    max-width: 1173px;
    margin: 0 auto;
  }

  ui li:hover {
    cursor: pointer;
  }

  .yahei {
    font-family: "Microsoft Yahei", 微软雅黑, Tahoma, Arial, Helvetica, STHeiti;
  }

  a {
    color: white;
  }

  .foot {
    width: 100%;
    background: #474443;
    color: #fff;
    padding: 20px 0;

    &-left {
      flex: 1;
      font-size: 12px;
      font-weight: bold;
      text-align: left;
    }

    &-nav span {
      margin-right: 25px;
      cursor: pointer;
    }

    &-subtext {
      font-weight: 400;
      opacity: 0.8;
    }

    &-right {
      font-size: 12px;
      font-weight: bold;
      text-align: left;

      /deep/ .el-divider {
        margin: 10px 0;
      }

      &-num {
        font-size: 20px;
      }

      &-box {
        display: flex;
      }

      &-img {
        width: 93px;
        height: 93px;
        margin-left: 50px;
      }

      &-question {
        font-size: 16px;
        cursor: pointer;

        .svg-icon {
          width: 20px;
          height: 20px;
          margin-right: 4px;
        }
      }
    }
  }

  .ml-2 {
    color: white;
  }

  .item {
    margin-top: -5px;
    margin-right: 5px;
  }
  //客户二维码
  .cService{
    position: fixed;
    right: 10px;
    top: 60%;
    &-icon{
      background: #bd3124;
      border-radius: 10px;
      width: 40px;
      height: 40px;
      cursor: pointer;
      text-align: center;
      line-height: 37px;
    }
    &-img{
      display: none;
      width: 200px;
      height: 200px;
      position: absolute;
      right: 50px;
      top: -70px;
      img{
        width: 100%;
      }
    }
    &:hover{
      .cService-img{
        display: block;
      }
    }
  }
</style>
