import Vue from 'vue'
import axios from 'axios';
// import global from '@/utils/global_variable'
var tokentemp = ''
if (localStorage.getItem('token')) {
    tokentemp =  localStorage.getItem('token')
    }

// import { ModalPlugin } from 'bootstrap-vue'
// Vue.use(ModalPlugin)
//设置请求时间
axios.defaults.timeout = 8000;
//请求地址
axios.defaults.baseURL = "/api";
axios.defaults.withCredentials = true;

// axios.defaults.headers.common['X-Auth-Token'] = store.state.user.token;
// axios.defaults.headers.common['LANGUAGE-TYPE-CODE'] = localStorage.getItem('langCode');
//http request 拦截器

axios.loaderEl = "";
axios.interceptors.request.use(
    config => {
        // const token = getCookie('名称');注意使用的时候需要引入cookie方法，推荐js-cookie
        // config.data = JSON.stringify(config.data);
        config.headers = {
            'X-Auth-Token': '',
            'LANGUAGE-TYPE-CODE': localStorage.getItem('langCode'),
            // 'token': tokentemp
            'token': localStorage.getItem('token')
            // 'token': 'eyJhbGciOiJIUzUxMiJ9.eyJqdGkiOiI4ODgiLCJzdWIiOiJ7XCJibGFja2xpc3RcIjowLFwiaWRcIjoxNDY4ODQ4MjQ4MDA3NDQ2NTI5LFwiaXNsb2FkaW5nXCI6MCxcIm1lbWJlcmNvZGVcIjpcIjEyMzE0XCIsXCJtZW1iZXJuYW1lXCI6XCLlsI_ni7xcIixcInNleFwiOjAsXCJ0ZWxcIjpcIjE1NTA0MjUzODk1XCJ9IiwiaWF0IjoxNjQ0NTcxMDU2LCJyb2xlcyI6ImNuYXNzZXRzIiwiZXhwIjoxNjQ0NjE0MjU2fQ.aRSFfDJVjk-bPJ0roNdyO_f7790O5FgIOq5ovsiFBwlYv6QknpLiJWT9bMol7WJAqCY_LMr_u5N5-XXKxMOCCw'
        }

        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

//http response 拦截器
// axios.interceptors.response.use(
//     response => {
//         if(response.data.resultCode == "401"){
//             if(axios.loaderEl!=undefined&&response.config.data.noLoading!=true&&axios.loaderEl.isActive==true){
//                 axios.loaderEl.hide();
//                 axios.loaderEl="";
//             }
//             // $store.commit('updateLogin',true);
//           //   showMsg('Please login again','',() => {
//           //
//           //    })
//         }
//
//         return response;
//     },
//     error => {
//         showMsg(error.message)
//         return Promise.reject(error)
//     }
// )

// export function showMsg(message,title,handleOk) {
//     //展示弹窗之前，关闭loading效果
//     if(axios.loaderEl!=undefined&&axios.loaderEl.isActive==true){
//         axios.loaderEl.hide();
//         axios.loaderEl="";
//     }
//     //调用公共弹窗方法
//     // global.showMsg(message,title,handleOk);
// }
/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function fetch(url, params = {}, noLoading) {
    return new Promise((resolve, reject) => {
        if (noLoading != true) {
            //loader.hide();
            axios.loaderEl = showLoading();
        }
        axios.get(url, {
            params: params
        })
            .then(response => {
                if (noLoading != true && axios.loaderEl.isActive == true) {
                    axios.loaderEl.hide();
                    axios.loaderEl = "";
                }
                resolve(response.data);
            }, err => {
                if (noLoading != true && axios.loaderEl.isActive == true) {
                    axios.loaderEl.hide();
                    axios.loaderEl = "";
                }
                reject(err)

            })
    })
}


/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data = {}, noLoading, config) {
    return new Promise((resolve, reject) => {
        if (noLoading != true) {
            //loader.hide();
            // axios.loaderEl=showLoading();
        }
        axios.post(url, data, config)
            .then(response => {
                if (noLoading != true && axios.loaderEl.isActive == true) {
                    axios.loaderEl.hide();
                    axios.loaderEl = "";
                }
                resolve(response.data);
            }, err => {
                if (noLoading != true && axios.loaderEl.isActive == true) {
                    axios.loaderEl.hide();
                    axios.loaderEl = "";
                }
                reject(err)
            })
    })
}

export function allRequest(request) {
    return new Promise((resolve, reject) => {
        axios.loaderEl = showLoading();
        axios.all(request)

            .then(response => {
                if (axios.loaderEl.isActive == true) {
                    axios.loaderEl.hide();
                    axios.loaderEl = "";
                }
                resolve(response.data);
            }, err => {
                if (axios.loaderEl.isActive == true) {
                    axios.loaderEl.hide();
                    axios.loaderEl = "";
                }
                reject(err)
            })
    })
}

export function showLoading(){
    return Vue.$loading.show({
        canCancel: false,
        color: '#246efc',
    });
}
export default axios;
