
export default {
  state: {
    token: "", // 登录的token
  },
  getters: {
    getToken (state) {
      return state.token
    }
  },
  mutations: {
    setToken (state, data) {
      state.token = data;
    }
  },
  actions: {
    setToken ({ commit }, data) {
      commit('setToken', data);
    }
  }
}